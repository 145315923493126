<template>
  <div class="activity">
    <el-card
      style="width: 100%; border-radius: 12px; margin: 16px; padding: 10px; box-sizing: border-box"
    >
      <span>活动状态：</span>
      <el-button
        v-for="item in el_button_demo"
        :key="item.value"
        :type="queryInfo.activityStatus == item.value ? 'primary' : ''"
        @click="activityStatusChange(item.value)"
        >{{ item.name }}</el-button
      >
      <span style="margin-left: 144px">活动名称：</span>
      <el-input
        style="width: 284px; margin-right: 40px"
        clearable
        v-model="queryInfo.activityName"
        placeholder="请输入活动名称"
      >
      </el-input>

      <el-button @click="reset">重置</el-button>
      <el-button type="primary" @click="search()">搜索</el-button>
      <el-button type="primary" style="float: right" @click="handleAdd">+ 新增活动</el-button>
    </el-card>
    <div
      class="activity-list"
      v-for="item in dataActivityList"
      :key="item.index"
      style="margin: 16px; border-radius: 12px"
    >
      <div class="activity-right">
        <img class="activity-img" :src="item.activityCover" />
        <span v-if="item.activityStatus === '未开始'" class="one">待开始</span>
        <span v-else-if="item.activityStatus === '进行中'" class="two">进行中</span>
        <span v-else-if="item.activityStatus === '已结束'" class="three">已结束</span>
      </div>
      <!-- @mouseenter="mouseenterShow(item)" -->
      <div class="qrcode">
        <el-popover placement="left-start" width="180" trigger="hover">
          <div
            class="pop_image"
            style="
              display: flex;
              flex-direction: column;
              align-items: center;
              width: 100%;
              overflow: hidden;
            "
          >
            <el-image
              v-loading="loading"
              slot="reference"
              style="width: 108px; height: 108px; margin: 10px 0"
              :src="downloadImges"
            ></el-image>
            <el-button size="mini" @click="downLoadImg('QRcode')" plain>导出图片</el-button>
          </div>
          <el-image slot="reference" style="width: 48px; height: 48px" :src="imageUrl"></el-image>
        </el-popover>
      </div>

      <div class="activity-left">
        <div style="font-weight: bold; font-size: 18px">{{ item.activityName }}</div>
        <div style="margin-top: 11px">
          <i style="color: #ada8b1" class="iconfont icon-jr-icon-up" />
          <span>主办方：{{ item.companyName }}</span>
        </div>
        <div style="margin-top: 6px">
          <i style="color: #ada8b1" class="iconfont icon-jr-icon-address1" />
          <span
            >参与方式： <span>{{ item.isParticipate }}</span> &nbsp;<span
              v-show="item.isParticipate === '线下' ? 'dispay-item' : ''"
              >{{ item.city + item.activityAddress }}</span
            >
          </span>
        </div>
        <div style="margin-top: 6px">
          <i style="color: #ada8b1" class="iconfont icon-jr-icon-time" />
          <span>时间：{{ item.endTime }} 至 {{ item.startTime }}</span>
        </div>
        <!-- <div style="margin-top:6px;">
          <i style="color: #ADA8B1;" class="iconfont icon-jr-icon-money" />
          <span>金额：{{ item.price }}</span>

        </div> -->

        <!-- </div> -->
        <!-- <div v-if="item.isParticipate === '线下'" style="margin-top:6px;">
          <i style="color: #ADA8B1;" class="iconfont icon-jr-icon-address1" />
          <span>地点： {{ item.province + item.city }}</span>
        </div>
        <div v-else style="margin-top:6px;">
          <i style="color: #ADA8B1;" class="iconfont icon-jr-icon-address1" />
          <span>形式： {{ item.isParticipate }}</span>
        </div> -->

        <div style="display: flex; margin-top: 6px">
          <span>成功报名：{{ item.successCount }}</span>
          <div style="margin-left: 30px">
            <img
              style="width: 18px; vertical-align: middle; margin-right: 5px"
              src="../../assets/activity-icon-sign.svg"
            />
            <span style="cursor: pointer" @click="handleManage(item)">报名管理</span>
          </div>

          <div class="sign_up_manage">
            <img style="width: 16px; margin-right: 5px" src="../../../static/images/Subtract.png" />
            <span style="cursor: pointer" @click="ClickManage(item)">签到管理</span>
          </div>
          <!-- 分销管理 -->
          <div class="distribution_manage">
            <img style="width: 16px; margin-right: 5px" src="../../../static/images/Vector.png" />
            <span style="cursor: pointer" @click="ClickDistribution(item)">分销管理</span>
          </div>
          <div class="activity-handle">
            <!-- <el-row style="width: 100%;" :gutter="2">
              <el-col :span="6">
                <div style="margin-left:7px">
                  <img style="width:15px; height: 26px; vertical-align: middle;"
                    src="../../assets/publish-icon-edit.svg" />
                  <span class="font-style" @click="handleRelease(item)"
                    :style="{ color: (item.isSued == 1 ? '#C4C4C4' : '#333333') }">{{ item.isSued == 1 ?
                        '已发布' : '发布'
                    }}</span>
                </div>
              </el-col>
              <el-col :span="6">
                <div style="margin-left:10px;">
                  <img style="width:15px; height: 26px; vertical-align: middle;"
                    src="../../assets/redact-icon-editt.svg" />
                  <span class="font-style" @click="handleEdit(item.id)">编辑</span>
                </div>
              </el-col>

              <el-col :span="6">
                <div>
                  <i class="iconfont icon-jr-icon-delete" style="color:#FFBDA9; width:15px;" />
                  <span class="font-style" @click="handleDelete(item.id)">删除</span>
                </div>
              </el-col>
            </el-row> -->
            <el-button class="pure_button" @click="handleRelease(item)">{{
              item.isSued == 1 ? '已发布' : '发布'
            }}</el-button>
            <el-button class="pure_button" @click="handleEdit(item.id)">编辑</el-button>
            <el-button
              class="pure_button"
              :class="item.activityStatus == '已结束' ? 'finished' : ''"
              @click="createdDistribution(item)"
              >创建分销</el-button
            >
            <div class="del" @click="handleDelete(item.id)">删除</div>
          </div>
        </div>
      </div>
    </div>
    <div class="new_page">
      <el-pagination
        :current-page="queryInfo.current"
        :page-sizes="[10, 30, 50]"
        :page-size="queryInfo.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="queryInfo.total"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
      />
    </div>

    <!-- 创建分销弹出来的对话框 -->
    <div class="dialog">
      <el-dialog :visible.sync="dialogVisible" width="987px" @close="closeClear">
        <div class="head">
          <div class="capsule"></div>
          <div class="head_text">创建分销</div>
        </div>
        <div class="context">
          <div class="img">
            <img :src="createdDistributionData.activityCover" alt="" />
          </div>
          <div class="text">
            <div class="title">{{ createdDistributionData.activityName }}</div>
            <div class="sponsor">
              <i class="el-icon-office-building"></i>
              主办方：{{ createdDistributionData.companyName }}
            </div>
            <div class="time">
              <i class="el-icon-time"></i>
              时间：
              {{ createdDistributionData.startTime }}
              至
              {{ createdDistributionData.endTime }}
            </div>
          </div>
        </div>

        <div class="validTime">
          <div class="text">分销有效期<span>*</span> ：</div>
          <div class="time">
            <el-date-picker
              v-model="dialogTime"
              type="daterange"
              range-separator="至"
              start-placeholder="开始"
              end-placeholder="结束"
              value-format="yyyy-MM-dd"
              @change="timeChange"
            >
            </el-date-picker>
          </div>
        </div>

        <div class="search">
          <el-input
            placeholder="请输入姓名查询"
            v-model="dialogInputVla"
            clearable
            style="width: 313px"
          >
          </el-input>
          <el-button @click="dialogClickSearch" class="deep_button">搜索</el-button>
        </div>

        <div class="table">
          <el-table
            ref="multipleTable"
            :data="dialogTableData"
            tooltip-effect="dark"
            style="width: 100%"
            :header-cell-style="{ background: '#F6F6F6', color: '#333333' }"
            @selection-change="dialogHandleSelectionChange"
          >
            <el-table-column type="selection" width="55" align="center"> </el-table-column>
            <el-table-column label="编号" width="120" align="center">
              <template slot-scope="scope">{{ scope.row.id }}</template>
            </el-table-column>
            <el-table-column label="用户头像" width="120" align="center">
              <template slot-scope="scope">
                <div class="imgaeBox">
                  <img :src="scope.row.headPortrait" alt="" />
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="userName" label="姓名" align="center"> </el-table-column>
            <el-table-column prop="address" label="用户等级" align="center">
              <template slot-scope="scope">
                <div>
                  <div v-if="scope.row.whetherMember == 1">普通会员</div>
                  <div v-else>超级会员</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="address" label="参与时间" align="center" sortable>
              <template slot-scope="scope">
                {{ scope.row.createTime }}
              </template>
            </el-table-column>
          </el-table>

          <div class="pagination">
            <el-pagination
              @size-change="dialogHandleSizeChange"
              @current-change="dialogHandleCurrentChange"
              :current-page="selectMemberQuery.current"
              :page-sizes="[10, 30, 50]"
              :page-size="selectMemberQuery.size"
              layout="total, sizes, prev, pager, next, jumper"
              :total="dialogtotal"
            >
            </el-pagination>
          </div>

          <div class="button">
            <el-button class="deep_button" @click="BuildSend">生成并发送</el-button>
            <el-button class="pure_button" @click="clickCancel">取消</el-button>
          </div>
        </div>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { activityList, deleteList, getupdateState, getQrcCode } from '@/api/activity.js'
import { selectMember, saveDistribution } from '../../api/distribution'
export default {
  name: 'Activity',
  data() {
    return {
      loading: true,
      imageUrl:
        'https://elitego-data-dev-1300257084.cos.ap-shanghai.myqcloud.com/blue-micro/Group%202531.png',
      downloadImges: '',
      input: '',
      queryInfo: {
        current: 1,
        size: 10,
        activityName: '',
        activityStatus: '',
        guestId: '',
        total: 0
      },
      dataActivityList: [],
      el_button_demo: [
        { name: '全部', value: '' },
        { name: '待开始', value: '待开始' },
        { name: '进行中', value: '进行中' },
        { name: '已结束', value: '已结束' }
      ],
      // 创建分销的对话框显示与隐藏
      dialogVisible: false,
      // 创建分销的数据对象
      createdDistributionData: {},
      dialogTime: '',
      dialogInputVla: '',
      dialogTableData: [],
      selectMemberQuery: {
        current: 1,
        size: 10,
        name: '',
        type: 0,
        typeId: '',
        order: ''
      },
      // 总数据条数
      dialogtotal: 0,
      tableIdList: [],
      createdDistributionDataEndTime: '',
      createdDistributionDataStartTime: ''
    }
  },
  created() {
    this.search()
  },
  methods: {
    //鼠标进入
    async mouseenterShow(row) {
      this.downloadImges = ''
      const res = await getQrcCode({ sceneStr: row.id })
      if (res.status === 200) {
        this.downloadImges = res.data
      }
    },
    handleSizeChange(val) {
      this.queryInfo.size = val
      this.search()
    },
    handleCurrentChange(val) {
      this.queryInfo.current = val
      this.search()
    },
    //新增活动
    handleAdd() {
      this.$router.push({
        name: 'newlist',
        query: { activityInfoType: 0 }
      })
    },
    async search() {
      this.loading = true
      const { data: res } = await activityList(this.queryInfo)
      if (res.resultCode === 200) {
        // console.log(res)
        this.dataActivityList = res.data.list
        console.log(this.dataActivityList)
        this.queryInfo.total = res.data.total
        this.loading = false
      }
    },
    /**
     * 删除方法
     * @param {*} id 根据ID进行删除
     */
    handleDelete(id) {
      this.$confirm(`确认删除吗?`, `提示`, {
        type: 'warning',
        confirmButtonText: '确定',
        cancelButtonText: '取消'
      })
        .then(async () => {
          const { data: res } = await deleteList({ id: id })
          if (res.resultCode === 200) {
            // console.log(res)
            this.$messageSuccess(`删除成功！`)
          } else {
            this.$messageError('删除失败，请重试。')
          }
          this.search()
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    /**
     * 编辑方法
     * @param {*} id
     */
    handleEdit(id) {
      // this.$router.push({
      //   name: 'newlist',
      //   params: {
      //     id: id,
      //     activityInfoType: 1
      //   }
      // })
      // console.log(id)
      this.$router.push({
        name: 'newlist',
        query: { activityInfoType: 1, id: id }
      })
    },
    handleRelease(row) {
      // console.log(row)
      if (row.isSued * 1) {
        this.$confirm(`确认取消发布${row.activityName}吗？`, {
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await getupdateState(row)
            if (res.resultCode === 200) {
              this.$messageSuccess('取消发布成功')
            } else {
              this.$messageError('取消发布失败')
            }
            this.search()
          })
          .catch(() => {})
      } else {
        this.$confirm(`确认发布${row.activityName}吗？`, {
          type: 'warning'
        })
          .then(async () => {
            const { data: res } = await getupdateState(row)
            if (res.resultCode === 200) {
              this.$messageSuccess('发布成功')
            } else {
              this.$messageError('发布失败')
            }
            this.search()
          })
          .catch(() => {})
      }
    },
    searchData() {
      this.dataActivityList = this.list
    },
    /**
     * 状态按钮点击触发
     * @param {*} status 状态值
     */
    activityStatusChange(status) {
      //修改接口参数里面的状态值
      this.queryInfo.activityStatus = status
      //调用搜索方法搜索
      this.search()
    },
    //重置方法
    reset() {
      this.queryInfo.current = 1
      this.queryInfo.activityName = ''
      this.activityStatus = ''
      this.search()
    },
    handleManage(row) {
      this.$router.push({
        name: 'manageList',
        query: { id: row.id }
      })
    },
    // 创建分销
    createdDistribution(row) {
      // console.log(row);
      if (row.activityStatus == '已结束') {
        return this.$message('此活动已结束')
      }
      this.dialogVisible = true
      this.createdDistributionData = row
      this.createdDistributionDataEndTime = new Date(this.createdDistributionData.endTime).getTime()
      this.createdDistributionDataStartTime = new Date(
        this.createdDistributionData.startTime
      ).getTime()
      // console.log(this.createdDistributionDataEndTime)
      // console.log(this.dialogTime)
      this.selectMemberQuery.typeId = row.id

      this.getSelectMember()
    },
    // 点击搜索
    dialogClickSearch() {
      this.selectMemberQuery.name = this.dialogInputVla
      this.getSelectMember()
    },
    // 表格选中的数据
    dialogHandleSelectionChange(val) {
      this.tableIdList = []
      // console.log(val)
      val.forEach((item) => {
        this.tableIdList.push(item.id)
      })
      // console.log(this.tableIdList)
    },
    // 获取表格数据
    async getSelectMember() {
      const { data: res } = await selectMember(this.selectMemberQuery)
      if (res.resultCode === 200) {
        this.dialogTableData = res.data.list
        this.dialogtotal = res.data.total
      }
      // console.log(this.dialogTableData)
    },
    dialogHandleSizeChange(val) {
      this.selectMemberQuery.size = val
      this.getSelectMember()
    },
    dialogHandleCurrentChange(val) {
      this.selectMemberQuery.current = val
      this.getSelectMember()
    },
    // 生成并且发送
    async BuildSend() {
      if (this.tableIdList.length === 0) {
        return this.$message.warning('请勾选数据')
      }
      if (this.dialogTime == [] || this.dialogTime == '') {
        return this.$message.warning('请选择分销时间')
      }
      const { data: res } = await saveDistribution({
        endTime: this.dialogTime[1],
        idList: this.tableIdList,
        startTime: this.dialogTime[0],
        type: 0,
        typeId: this.createdDistributionData.id
      })
      if (res.resultCode === 200) {
        this.$message.success('发送成功')
        // 关闭弹出框
        this.dialogVisible = false
      }
    },
    // 取消
    clickCancel() {
      this.dialogVisible = false
    },
    // 分销时间
    timeChange() {
      if (this.dialogTime != null) {
        if (new Date(this.dialogTime[1]).getTime() > this.createdDistributionDataEndTime) {
          // console.log(this.createdDistributionData.endTime)
          // console.log(new Date(this.dialogTime[1]));
          this.dialogTime[1] = this.createdDistributionData.endTime
          //  console.log(new Date(this.dialogTime[1]));
          //  console.log(this.dialogTime);
        }

        if (
          new Date(this.dialogTime[0]).getTime() > this.createdDistributionDataEndTime ||
          new Date(this.dialogTime[0]).getTime() < this.createdDistributionDataStartTime
        ) {
          // console.log(this.createdDistributionData.startTime)
          this.dialogTime[0] = this.createdDistributionData.startTime
        }
      }
      // console.log(this.dialogTime)
    },
    // 关闭对话框
    closeClear() {
      this.dialogTime = ''
      this.dialogInputVla = ''
    },
    // 签到管理
    ClickManage(row) {
      this.$router.push({
        path: '/activity/signInManage',
        query: { id: row.id }
      })
      // console.log(row)
    },
    ClickDistribution(row) {
      this.$router.push({
        path: '/activity/distributionManage',
        query: { query: JSON.stringify(row) }
      })
      // sessionStorage.setItem('routeRow1', JSON.stringify(row))
      // console.log(row)
      // console.log('分销管理')
    }
  }
}
</script>

<style lang="less" scoped>
.activity {
  width: 100%;
  background: #e5e5e5;
  box-sizing: border-box;

  .qrcode {
    width: 48px;
    height: 48px;
    position: absolute;
    top: 0;
    right: 20px;
    cursor: pointer;
  }

  .activity-list {
    width: 100%;
    overflow: hidden;
    background-color: #ffffff;
    display: flex;
    position: relative;
  }

  .activity-right {
    position: relative;
    margin: 20px;
  }

  .activity-img {
    width: 300px;
    height: 150px;
    border-radius: 8px;
  }

  .activity-title {
    width: 78px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 16px;
    color: #ffffff;
    background: #fe6c41;
    border-radius: 2px 0px 8px;
  }

  .activity-star {
    width: 78px;
    height: 32px;
    line-height: 32px;
    text-align: center;
    position: absolute;
    top: 0px;
    left: 0px;
    font-size: 16px;
    color: #333333;
    background: #f0f0f0;
    border-radius: 2px 0px 8px;
  }

  .activity-left {
    flex: 1;
    margin-top: 15px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    padding: 0 20px 0 0;
  }

  .activity-line {
    width: 20px;
    height: 0px;
    border-bottom: 1px solid #e8e8e8;
    margin: 0 auto;
  }

  .new_page {
    width: 100%;
    text-align: center;
    margin: 30px auto;
  }
}
.sign_up_manage {
  display: flex;
  align-items: center;
  margin: 0 15px;
}

.activity-handle {
  width: 350px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  position: absolute;
  top: 50%;
  right: 61px;
  // transform: translateY(-50%);

  .del {
    font-size: 14px;
    color: #8f9399;
    margin-left: 25px;
  }
}
.distribution_manage {
  display: flex;
  align-items: center;
}

.font-style {
  font-family: 'PingFang SC';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  cursor: pointer;
  margin-left: 5px;
}
.dispay-item {
  display: none;
}
.one {
  width: 78px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
  color: #333333;
  border-radius: 2px 0px 8px;
  background-color: #f0f0f0;
}
.two {
  width: 78px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 2px 0px 8px;
  background: #fe6c41;
}
.three {
  width: 78px;
  height: 32px;
  line-height: 32px;
  text-align: center;
  position: absolute;
  top: 0px;
  left: 0px;
  font-size: 16px;
  color: #ffffff;
  border-radius: 2px 0px 8px;
  background-color: #ababab;
}

.dialog {
  .head {
    display: flex;
    transform: translate(-50px, -40px);
    .capsule {
      width: 4px;
      height: 21px;
      border-radius: 10px;
      background-image: linear-gradient(#7563ff, #5974ff);
    }
    .head_text {
      font-size: 16px;
      color: #333;
      margin-left: 12px;
    }
  }

  .context {
    box-sizing: border-box;
    width: 863px;
    height: 110px;
    background-color: #f3f3f3;
    border-radius: 4px;
    padding: 10px;
    display: flex;
    .img {
      width: 166px;
      height: 90px;
      margin-right: 12px;
      img {
        width: 100%;
        height: 100%;
        border-radius: 8px;
      }
    }
    .text {
      .title {
        font-size: 20px;
        font-weight: 700;
        color: #333;
        margin-bottom: 16px;
      }
      .sponsor {
        font-size: 14px;
        color: #333;
        margin-bottom: 5px;
      }
      .time {
        font-size: 14px;
        color: #333;
      }
    }
  }
  .validTime {
    height: 67px;
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #eeecec;
    .text {
      font-size: 16px;
      color: #333;
      margin-right: 15px;
      span {
        color: #f05252;
      }
    }
  }

  .search {
    height: 73px;
    display: flex;
    align-items: center;
    .deep_button {
      margin-left: 7px;
    }
  }

  // .table {
  // }
  .pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;

    /deep/.active {
      width: 28px;
      height: 28px;
      border-radius: 2px;
      background-color: #7c71e1;
      color: #fff;
      font-size: 12px;
      min-width: 28px;
      font-weight: 400;
    }
  }

  .button {
    display: flex;
    justify-content: center;
  }
}

// 浅
.pure_button {
  border: 1px solid #7c71e1;
  color: #7c71e1;
  font-size: 14px;
  border-radius: 4px;
}
.pure_button:hover {
  border-radius: 4px;
  background-color: rgba(124, 113, 225, 0.4);
  color: #7c71e1;
  font-size: 14px;
  border: 1px solid #7c71e1;
}

// 深
.deep_button {
  border-radius: 4px;
  background-color: #6e61e9;
  color: #fff;
  font-size: 14px;
}
/deep/.el-dialog__body {
  padding: 20px 68px 30px 56px;
}
.imgaeBox {
  width: 100%;
  display: flex;
  justify-content: center;

  img {
    width: 48px;
    height: 48px;
    border-radius: 4px;
  }
}

/deep/.el-dialog {
  margin-top: 5vh !important;
}
.finished {
  background-color: #fff !important;
  color: #8f9399;
  border: 1px solid #8f9399;
}
</style>
